<template>
  <section>
    <b-card title="Blending 01">
    <Blending01></Blending01>
    </b-card>
    <b-card title="Blending 02">
    <Blending02></Blending02>
    </b-card>
    <b-card title="Blending 03">
    <Blending03></Blending03>
    </b-card>
    <b-card title="Blending 04">
    <Blending04></Blending04>
    </b-card>
  </section>
</template>


<script>
import {
  BTitle,
  BCard,
  BRow,
  BCol,
  BButton,
  BLink,
} from "bootstrap-vue";

import useApiServices from '@/services/useApiServices.js';
import AgGridTable from '@/views/brunacomponents/ag-grid-table/AgGridTable.vue'
import Blending01 from './Blending01.vue';
import Blending02 from './Blending02.vue';
import Blending03 from './Blending03.vue';
import Blending04 from './Blending04.vue';




export default {

  components: {
    BTitle,
    BCard,
    AgGridTable,
    BRow,
    BCol,
    BButton,
    BLink,
    Blending01,
    Blending02,
    Blending03,
    Blending04
},

  data() {
    return {

      detallesPlanificacionConfig: useApiServices.detallesPlanificacionConfig,
      detallesPlanificacionData: useApiServices.detallesPlanificacionData,
      detallesPlanificacionRecipe: null,
      
    }
  }, mounted() {

    useApiServices.getDetallesPlanificacionRecipeData(this).then((response) => {
      this.detallesPlanificacionRecipe = response.data;
    })
      .catch((error) => {
        console.log(error);
      });

  },
};

</script>

<style lang="scss" scoped>
.tg {
  border-collapse: collapse;
  border-spacing: 0;
}

.tg td {
  border-style: solid;
  border-width: 1px;
  font-size: 14px;
  overflow: hidden;
  padding: 10px 5px;
  word-break: normal;
}

.tg th {
  border-style: solid;
  border-width: 1px;
  font-size: 14px;
  font-weight: normal;
  overflow: hidden;
  padding: 10px 5px;
  word-break: normal;
}

.tg .tg-v3yz {
  background-color: #f8ff00;
  border-color: #000000;
  text-align: left;
  vertical-align: top;
}

.tg .tg-xwyw {
  border-color: #000000;
  text-align: center;
  vertical-align: middle;
}

.tg .tg-txdf {
  background-color: #f8ff00;
  border-color: #000000;
  text-align: center;
  vertical-align: top;
}

.tg .tg-73oq {
  border-color: #000000;
  text-align: left;
  vertical-align: top;
}
</style>