<template>
  <section>
    <div v-if="this.detallesPlanificacionRecipe" class="statisc" style="margin-left: 20px; margin-top: 30px;">
      <div class="statisc2" style="width: 25%;">
        <div class="">
          <h5>Fecha Planificación: {{ this.detallesPlanificacionRecipe.meta_data.date_fabrication }}</h5>
        </div>
        <div class="">
          <h5>Fecha Produción: 00/00/00</h5>
        </div>
        <div class="">
          <h5>Contrato Associado: xxx</h5>
        </div>
        <div class="">
          <h5>Lote Destino: {{ this.detallesPlanificacionRecipe.meta_data.lote_destino }}</h5>
        </div>
        <div class="">
          <h5>Tiempo Blending: xxx</h5>
        </div>
      </div>
      <div class="form-check" style="margin-bottom: 20px; margin-top: 20px; height: 70px;">
        <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
        <label class="form-check-label" for="flexCheckDefault">
          <h5>Asociar codigo de blending QAD</h5>
        </label>
      </div>
    </div>
    <div v-if="this.detallesPlanificacionRecipe" class="tg" style="margin-left:20px;">
      <b-thead>
        <b-tr>
          <b-td class="tg-xwyw" colspan="3" rowspan="3">FOLIO: {{ this.detallesPlanificacionRecipe.meta_data.folio }}
          </b-td>
          <b-td class="tg-v3yz">Esperado</b-td>
          <b-td class="tg-txdf">lote-UP</b-td>
          <b-td class="tg-txdf">HUMEDAD</b-td>
          <b-td class="tg-txdf">GRASA</b-td>
          <b-td class="tg-txdf">PROT</b-td>
          <b-td class="tg-txdf">CEN</b-td>
          <b-td class="tg-txdf">TVN</b-td>
          <b-td class="tg-txdf">FFA</b-td>
          <b-td class="tg-txdf">PER</b-td>
          <b-td class="tg-txdf">TOTAL</b-td>
        </b-tr>
        <b-tr>
          <b-td class="tg-v3yz" colspan="2"><i>{{ this.detallesPlanificacionRecipe.meta_data.lote_destino }}</i></b-td>
          <b-td class="tg-txdf">{{ this.detallesPlanificacionRecipe.meta_data.humidity }}</b-td>
          <b-td class="tg-txdf">{{ this.detallesPlanificacionRecipe.meta_data.grasa }}</b-td>
          <b-td class="tg-txdf">{{ this.detallesPlanificacionRecipe.meta_data.protein }}</b-td>
          <b-td class="tg-txdf">{{ this.detallesPlanificacionRecipe.meta_data.ceniza }}</b-td>
          <b-td class="tg-txdf">{{ this.detallesPlanificacionRecipe.meta_data.tvn }}</b-td>
          <b-td class="tg-txdf">{{ this.detallesPlanificacionRecipe.meta_data.ffa }}</b-td>
          <b-td class="tg-txdf">{{ this.detallesPlanificacionRecipe.meta_data.perox }}</b-td>
          <b-td class="tg-txdf">{{ this.detallesPlanificacionRecipe.somatorio }}</b-td>
        </b-tr>
        <b-tr>
          <td class="tg-73oq" colspan="10">
            <i>OPERADOR: {{ this.detallesPlanificacionRecipe.meta_data.operador }} </i>
          </td>
        </b-tr>
      </b-thead>
    </div>
    <b-row>
      <b-col sm="12">
        <ag-grid-table :configUrl="detallesPlanificacionConfig" :dataUrl="detallesPlanificacionData">

        </ag-grid-table>
        <!-- <b-link style="font-size: 15px;">Download Link</b-link> -->
        <b-button style="height: 50px; background-color: #053743 !important; margin-left: 20px;">Download</b-button>
        <b-button href="/dashboard/fiordo/blending/detalle-planificacion"
          style="height: 50px; background-color: #053743 !important; float:right; margin-right: 20px; padding-top: 17px;">
          Volver
          a Detalle Diario</b-button>
      </b-col>
    </b-row>
  </section>
</template> 
<script>
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BLink,
  BCardTitle,
  BTableSimple
} from "bootstrap-vue";

import useApiServices from '@/services/useApiServices.js';
import AgGridTable from '@/views/brunacomponents/ag-grid-table/AgGridTable.vue'
import Blending01 from './Blending01.vue';
import LayoutVerticalVue from "@/@core/layouts/layout-vertical/LayoutVertical.vue";
import LayoutContentRendererDefaultVue from "@/@core/layouts/components/layout-content-renderer/LayoutContentRendererDefault.vue";



export default {

  components: {
    LayoutVerticalVue,
    LayoutContentRendererDefaultVue,
    BCardTitle,
    BCard,
    AgGridTable,
    BRow,
    BCol,
    BButton,
    BLink,
    Blending01,
    BTableSimple
  },
  

  data() {
    return {
      detallesPlanificacionConfig: useApiServices.detallesPlanificacionConfig,
      detallesPlanificacionData: useApiServices.detallesPlanificacionData,
      detallesPlanificacionRecipe: null,

    }
  }, mounted() {

    useApiServices.getDetallesPlanificacionRecipeData(this).then((response) => {
      this.detallesPlanificacionRecipe = response.data;
    })
      .catch((error) => {
        console.log(error);
      });

  },
};

</script>
<style lang="scss" scoped>
.tg {
  border-collapse: collapse;
  border-spacing: 0;
}

.tg td {
  border-style: solid;
  border-width: 1px;
  font-size: 14px;
  overflow: hidden;
  padding: 10px 5px;
  word-break: normal;
}

.tg th {
  border-style: solid;
  border-width: 1px;
  font-size: 14px;
  font-weight: normal;
  overflow: hidden;
  padding: 10px 5px;
  word-break: normal;
}

.tg .tg-v3yz {
  background-color: #f8ff00;
  border-color: #000000;
  text-align: left;
  vertical-align: top;
}

.tg .tg-xwyw {
  border-color: #000000;
  text-align: center;
  vertical-align: middle;
}

.tg .tg-txdf {
  background-color: #f8ff00;
  border-color: #000000;
  text-align: center;
  vertical-align: top;
}

.tg .tg-73oq {
  border-color: #000000;
  text-align: left;
  vertical-align: top;
}
</style>